html, body {
  background-color: #282c34;
  padding: 0;
  margin: 0;
}

td, th {
  border: 1px solid gray;
}
table {
  border-collapse: collapse;
}
td {
  padding: 0.5em;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  font-size: calc(10px + 2vmin);
  color: white;
  border: none;
}

.App-link {
  color: white;
}

.no-border {
  border: none;
}

.no-padding {
  padding: 0;
}

.asx200-cell {
  border: 1px solid gray;
  padding: 0.5em;
  margin: 0.5em 0.5em 0 0;
}
